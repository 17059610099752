<template>
  <div class="ticket_collec_setting_view">
    <div class="top_box">
      <div class="tit">收票设置</div>
      <div>
        <span style="margin-right: 20px" for="">组织名称:</span>
        <el-select @change="orgIdchange" v-model="orgId" filterable placeholder="请选择">
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </div>
    </div>

    <div class="opt_box">
      <div class="opt_item" style="margin-bottom: 0px">
        自动入账规格：
        <el-checkbox style="margin: 0 25px" label="AUTO_ENTRY" v-model="verifyVal">已匹配发票自动入账</el-checkbox>
        <div class="_m">
          <i class="el-icon-info icon"></i>
          <span>自动入账规格自动入账规格</span>
        </div>
      </div>
    </div>

    <footer>
      <el-button type="default" @click="handleReset">重置</el-button>
      <el-button type="primary" @click="handleBuildParam">保存</el-button>
    </footer>
  </div>
</template>

<script>
import { getOrgOwn, putOrgOwn } from '@/service/system/config';
export default {
  name: "EtyRzsz",
  components: {},
  data() {
    return {
      verifyVal: false,
      orgId: '',
      options: []
    };
  },
  created() {
    let o = this.$store.state.user.organizations;
    this.options = [{ id: 0, name: '全部', taxNo: '' }, ...o];
    this.orgId = JSON.parse(localStorage.getItem('orgId'));
    this.handleGetVerifySwitch('AUTO_ENTRY');
  },
  methods: {
    _checkedOpt(val, code, name, remark) {
      const sysConfig = {
        code,
        value: val ? 'Y' : 'N',
        type: 'TNT',
        name,
        remark,
        status: '0'
      };
      putOrgOwn({
        orgId: this.orgId, configs: { AUTO_ENTRY: sysConfig.value }
      }).then(({ success,data }) => {
        if (success) {
          this.verifyVal = data.AUTO_ENTRY
          this.toast('保存成功！', 'success');
          this.handleGetVerifySwitch('AUTO_ENTRY')
        }
      });
    },
    handleReset() {
      this.verifyVal = false;
      this.handleBuildParam();
    },
    handleBuildParam() {
      this._checkedOpt(this.verifyVal, 'AUTO_ENTRY', '租户:入账-自动入账', '租户:入账-自动入账');
    },
    async handleGetVerifySwitch(type) {
      await getOrgOwn({ orgId: this.orgId, configs: { AUTO_ENTRY: '' } }).then(({ success, data }) => {
        if (success) {
          this.verifyVal = data.configs.AUTO_ENTRY == 'Y';
        }
      });
    },
    orgIdchange(){
      this.handleGetVerifySwitch('AUTO_ENTRY');
    },
  }
};
</script>

<style scoped lang="scss">
.ticket_collec_setting_view {
  background: #fff;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  .top_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);
    padding: 16px 24px;
    .tit {
      border-left: 2px solid #999;
      padding-left: 10px;
      font-size: 16px;
      color: #333;
    }
  }
  .count_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 26px 24px;
    margin: 26px 24px;
    background: #ebf4ff;
    .num_box {
      font-size: 14px;
      color: #333;
      .num {
        font-size: 36px;
        color: #333333;
        font-weight: 600;
        vertical-align: middle;
        margin-right: 4px;
      }
    }
  }
  .opt_box {
    padding: 0 24px;
    font-size: 14px;
    color: #333;
    .check_set {
      font-size: 16px;
      margin: 20px 0;
    }
    .opt_item {
      display: flex;
      align-items: center;
      margin: 20px 0;
      .tip {
        margin-left: 26px;
        color: #999;
      }
    }
  }
}
._m {
  display: flex;
  padding: 10px;
  color: #999999;
  border-radius: 2px;
  align-items: center;
  background-color: #fafafa;
}
footer {
  position: absolute;
  bottom: 20px;
  // top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
